import React, { Fragment, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../store/actions/WhiteBoardActions';
import { S3Service } from '../../../services/S3.service';
import { useUserClassRoom } from '../../UserManagment/hooks/useUserClassRoom';
import { WhiteBoardRepository } from '../Canvas/WhiteBoardRepository';
import { v1 as uuidv1 } from 'uuid';
import { SHAPE_TYPES } from '../../../enums/whiteBoard.enum';
import API, { graphqlOperation } from '@aws-amplify/api';
/* COMPONENTS */
import { ReactComponent as UploadBlack } from 'assets/whiteboard/black/upload-black.svg';
import { ReactComponent as UploadGrey } from 'assets/whiteboard/grey/upload-grey.svg';
import { ReactComponent as UploadWhite } from 'assets/whiteboard/white/upload-white.svg';
import ModalUpload from '../Gallery/ModalUpload';

import { ICONS_COLORS } from '../../../enums/constants.enum';
import { getClassRoom } from 'graphql/queries';
import { audioAllowedExtensions } from 'utils/allowedExtensions';

const s3Service = new S3Service('public');

const UploadFileTool = ({
  color,
  openGallery,
  setOpenGallery,
  setCanvasImage,
  notifyDrawEvent,
  setCanvasAudio,
  openBackground,
  setOpenBackground,
  whiteBoardSocket
}) => {
  const inputFile = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const { assignedClassRoom } = useUserClassRoom();

  const showFile = async file => {
    console.log('image', file);
    if (!file) {
      alert('choose an image file');
      return;
    }
    const { classRoomId, classRoomIdVisiting } = assignedClassRoom;
    const classRommUserCurrent = parseInt(classRoomIdVisiting) || classRoomId;
    const classRoom = await API.graphql(
      graphqlOperation(getClassRoom, { id: classRommUserCurrent })
    );
    const page = classRoom.data.getClassRoom.page;
    const validAudioExtensions = audioAllowedExtensions;
    const audioExtension = file.name.split('.').pop().toLowerCase();

    let url = null;
    let shape = null;
    if (file.url) url = file.url;
    else {
      try {
        window.sessionStorage.setItem('isNewWhiteboardModification', 'true');

        const name = `whiteBoard/${classRommUserCurrent}/page/${page}/${file.name.replace(
          /\s/g,
          ''
        )}`;
        if (validAudioExtensions.includes(audioExtension)) {
          url = await s3Service.uploadAudio({ name, blob: file });
          shape = SHAPE_TYPES.AUDIO;
        } else {
          url = await s3Service.uploadImage({ name, blob: file });
          shape = SHAPE_TYPES.IMAGE;
        }
      } catch (error) {
        if (error.message === 'Choose an file') {
          alert('choose an image file');
        } else {
          console.error(error);
        }
      }
    }
    const payload = {
      id: file.url ? file.id : uuidv1(),
      classRoomId: classRommUserCurrent,
      page,
      shapeType: file.url ? SHAPE_TYPES.IMAGE : shape,
      name: file.name,
      url: url.fileUrl,
      isFromGallery: file.url ? true : false
    };
    if (payload.isFromGallery) {
      Object.assign(payload, file);
    }
    const whiteBoardEvent = new WhiteBoardRepository(payload);
    whiteBoardEvent.save();

    if (shape === SHAPE_TYPES.AUDIO) {
      setCanvasAudio(payload);
    } else {
      setCanvasImage(payload);
    }
    // openChat(false);
    // notify event to all users in the classRoom
    notifyDrawEvent({ ...payload, eventType: Actions.SET_CANVAS_FILE });
    setOpenModal(false);
  };
  useEffect(() => {
    if (openGallery) setOpenGallery(true);
    return () => setOpenGallery(false);
  }, [openGallery, setOpenGallery]);

  useEffect(() => {
    if (openBackground) setOpenBackground(true);
    return () => setOpenBackground(false);
  }, [openBackground, setOpenBackground]);

  return (
    <Fragment>
      <div
        onClick={() => {
          window.sessionStorage.setItem('fileUploadedFrom', 'device');
          inputFile.current.click();
        }}
      >
        <input
          type="file"
          ref={inputFile}
          value=""
          onChange={e => showFile(e.target.files[0])}
          style={{ display: 'none' }}
        />
        {color === ICONS_COLORS.BLACK && <UploadBlack className="page-icon-item" />}
        {color === ICONS_COLORS.WHITE && <UploadWhite className="page-icon-item" />}
        {color === ICONS_COLORS.GREY && <UploadGrey className="page-icon-item" />}
      </div>

      <ModalUpload
        openModal={openModal}
        onHide={() => {
          setOpenModal(false);
          setOpenGallery(false);
          setOpenBackground(false);
        }}
        inputOpen={() => {}}
        showFile={showFile}
        openGallery={openGallery}
        setOpenGallery={setOpenGallery}
        openBackground={openBackground}
        setOpenBackground={setOpenBackground}
      />
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  const { setCanvasImage, setOpenGallery, notifyDrawEvent, setCanvasAudio } = Actions;

  return bindActionCreators(
    {
      setCanvasImage,
      setCanvasAudio,
      notifyDrawEvent
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(UploadFileTool);
