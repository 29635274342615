import React, { useEffect, useState } from 'react';
import { useSocket } from '../../../providers/SocketProvider';
import PublicExamModal from './PublicExamModal';
import { USER_ROLES } from 'enums/constants.enum';

function PublicExam({ userRole }) {
  // states
  const [publicExamURL, setPublicExamURL] = useState('');
  const [isOpenExam, setIsOpenExam] = useState(false);
  //hooks
  const { whiteBoardSocket } = useSocket();

  const handleClosePublicExamModal = () => {
    if (userRole !== USER_ROLES.STUDENTS) {
      whiteBoardSocket.emit('CLOSE_PUBLIC_EXAM_IN_WHITEBOARD');
      localStorage.removeItem('OPEN_PUBLIC_EXAM');
    } else {
      setIsOpenExam(false);
    }
  };

  useEffect(() => {
    if (!whiteBoardSocket) {
      return;
    }
    const handlePublicChatMessage = data => {
      const { examUrl } = data;
      setPublicExamURL(examUrl);
      setIsOpenExam(true);
    };

    const handleCloseAllPublciExamModal = () => {
      setIsOpenExam(false);
    };

    if (whiteBoardSocket) {
      whiteBoardSocket.on('PUBLIC_EXAM_IN_WHTEBOARD_ACEPTED', handlePublicChatMessage);
      whiteBoardSocket.on(
        'PUBLIC_EXAM_IN_WHTEBOARD_CANCELED',
        handleCloseAllPublciExamModal
      );
    }
    return () => {
      if (whiteBoardSocket) {
        whiteBoardSocket.remove(
          'PUBLIC_EXAM_IN_WHITEBOARD_ACCEPTED',
          handlePublicChatMessage
        );
        whiteBoardSocket.remove(
          'PUBLIC_EXAM_IN_WHITEBOARD_CANCELED',
          handleCloseAllPublciExamModal
        );
      }
    };
  }, [whiteBoardSocket, isOpenExam]);
  useEffect(() => {}, [isOpenExam]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: isOpenExam ? 99999 : -1
      }}
    >
      {isOpenExam && (
        <PublicExamModal
          examUrl={publicExamURL}
          onClose={handleClosePublicExamModal}
          whiteBoardSocket={whiteBoardSocket}
        />
      )}
    </div>
  );
}

export default PublicExam;
