import React, { useState, useEffect, useRef } from 'react';
import * as Actions from 'store/actions/WhiteBoardActions';
import { Rnd } from 'react-rnd';
import { Group } from 'react-konva';
import {
  VolumeUp as VolumeUpIcon,
  FastRewind as FastRewindIcon,
  FastForward as FastForwardIcon,
  VolumeOff as VolumeOffIcon,
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  SkipPrevious as SkipPreviousIcon,
  Replay as ReplayIcon,
  SkipNext as SkipNextIcon,
  Close as CloseIcon,
  ControlCameraSharp as MoveIcon
} from '@material-ui/icons';
import Bar from './Bar';
import './AudioPlayerDashboard.sass';
import { useUserClassRoom } from 'components/UserManagment/hooks/useUserClassRoom';
import { useToggleClassRoomState } from 'components/Whiteboard/hooks/useToggleClassRoom';

const AudioPlayerDashboard = ({
  name,
  url,
  isCrystal,
  deleteCanvasAudio,
  toggleHideElements,
  selectedTool,
  setIsVisible,
  isVisible
}) => {
  const audioRef = useRef(null);
  const [audioBar, setAudioBar] = useState(false);
  const [duration, setDuration] = useState(1);
  const [curTime, setCurTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [lastPlayPauseClick, setLastPlayPauseClick] = useState(0);
  const [volume, setVolume] = useState(1);
  const [volumeVisible, setVolumeVisible] = useState(false);

  const { assignedClassRoom, isDisabled } = useUserClassRoom();
  const { setClassRoomState } = useToggleClassRoomState();

  const toggleBlockTool = () => {
    if (window.innerWidth < 600) {
      setIsVisible(!isVisible);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
      };

      const handleTimeUpdate = () => {
        setCurTime(audio.currentTime);
      };

      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [url]);
  const handleSeek = seconds => {
    if (audioRef.current) {
      const newTime = Math.min(
        Math.max(audioRef.current.currentTime + seconds, 0),
        duration
      );
      audioRef.current.currentTime = newTime;
      setCurTime(newTime);
    }
  };
  useEffect(() => {
    const handleKeyDown = event => {
      if (!audioBar) {
        return;
      }
      switch (event.key) {
        case 'ArrowLeft':
          handleSeek(-1);
          break;
        case 'ArrowRight':
          handleSeek(1);
          break;
        case 'Enter':
          handlePlayPause();
          break;
        case ' ':
          handlePlayPause();
          break;
        case 'r':
          handleRestart();
          break;
        case 'v':
          toggleVolumeControl();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSeek]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      playing ? audio.play() : audio.pause();
    }
  }, [playing]);

  useEffect(() => {
    if (curTime >= duration) {
      setPlaying(false);
    }
  }, [curTime, duration]);

  useEffect(() => {}, [selectedTool]);
  const toggleElements = () => {
    toggleBlockTool();
    setAudioBar(!audioBar);
    setPlaying(false);
  };

  const handlePlayPause = () => {
    const now = Date.now();
    if (now - lastPlayPauseClick < 300) return;
    setLastPlayPauseClick(now);
    setPlaying(prev => !prev);
  };

  const handleRestart = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setCurTime(0);
    }
  };

  const handleVolumeChange = event => {
    setVolume(parseFloat(event.target.value));
  };

  const toggleVolumeControl = () => {
    setVolumeVisible(prev => !prev);
  };

  const validAudioExtensions = ['mp3', 'wav', 'ogg', 'm4a', 'flac'];
  const audioExtension = name.split('.').pop().toLowerCase();

  if (!validAudioExtensions.includes(audioExtension)) {
    console.log('Error: Invalid audio file.');
    return null;
  }

  const handleClose = () => {
    const event = new Event(Actions.WINDOWS_EVENTS.CLOSE_CHANGE_PAGE_AUDIO);
    window.dispatchEvent(event);
  };

  return (
    <Group
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: selectedTool === 'PENCIL' || selectedTool === 'ERASER' ? -1 : 1
      }}
    >
      {!audioBar && (
        <div className="audio-player">
          <div className="audio-button">
            <VolumeUpIcon className="audio-icon" />
            <span
              className={isCrystal ? 'audio-name-crystal' : 'audio-name'}
              onClick={toggleElements}
              onTouchStart={toggleElements}
            >
              {name.length > 14 ? `${name.substring(0, 14)}...` : name}
            </span>
            <button
              className="audio-close"
              onClick={deleteCanvasAudio ? deleteCanvasAudio : handleClose}
              onTouchStart={deleteCanvasAudio ? deleteCanvasAudio : handleClose}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
      <Rnd
        default={{
          x: 0,
          y: 0
        }}
        dragHandleClassName={'drag-handle'}
      >
        {audioBar && (
          <div
            className={isCrystal ? 'audio-modal crystal' : 'audio-modal'}
            style={{
              top: window.innerHeight < 600 ? '23vh' : '',
              right: window.innerHeight < 600 && '50px'
            }}
          >
            <div className="controls">
              <button className="drag-handle" style={{ cursor: 'grab' }}>
                <MoveIcon fontSize="small" style={{ cursor: 'grabbing' }} />{' '}
              </button>
              <button onClick={() => handleSeek(-5)} onTouchStart={() => handleSeek(-5)}>
                <FastRewindIcon className="left" />
              </button>
              <button onClick={() => handleSeek(-1)} onTouchStart={() => handleSeek(-1)}>
                <SkipPreviousIcon className="left" />
              </button>
              <button
                onClick={handlePlayPause}
                onTouchStart={handlePlayPause}
                className="play"
              >
                {playing ? <PauseIcon /> : <PlayArrowIcon />}
              </button>
              <button
                onClick={handleRestart}
                onTouchStart={handleRestart}
                className="restart"
              >
                <ReplayIcon />
              </button>
              <button onClick={() => handleSeek(1)} onTouchStart={() => handleSeek(1)}>
                <SkipNextIcon />
              </button>
              <button onClick={() => handleSeek(5)} onTouchStart={() => handleSeek(5)}>
                <FastForwardIcon />
              </button>
              <button
                onClick={toggleVolumeControl}
                onTouchStart={toggleVolumeControl}
                className="volume-button"
              >
                {volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </button>
              {volumeVisible && (
                <div>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={handleVolumeChange}
                    className="volume-control"
                    style={{
                      background: `linear-gradient(to right, #7719BA ${
                        volume * 100
                      }%, rgba(0, 0, 0, 0.3) ${volume * 100}%)`
                    }}
                  />
                </div>
              )}
            </div>
            <Bar
              duration={duration}
              curTime={curTime}
              onTimeUpdate={setCurTime}
              isCrystal={isCrystal}
              toggleAudioBar={toggleElements}
              playing={playing}
              selectedTool={selectedTool}
              audioRef={audioRef}
            />
          </div>
        )}
      </Rnd>
      <audio ref={audioRef} src={url} style={{ display: 'none' }} />
    </Group>
  );
};

export default AudioPlayerDashboard;
