import React from 'react';
import styles from './styles.module.scss';
import { USER_ROLES } from 'enums/constants.enum';
import { useUserRole } from 'services/cognito.service';
import { Link } from 'react-router-dom';
import { BackgroundTool } from '../StudentCard/components/BackgroundTool';
import { useState } from 'react';
import TestComponents from './ButtonControlPanelComponents/TestComponents';
import ChangeBackgroundToolPanel from './ChangeBackgroundToolPanel';

export default function ButtonControlPanel() {
  const userRole = useUserRole();
  const [openTest, setOpenTest] = useState(false);

  return (
    <>
      {userRole === USER_ROLES.ADMINS ? (
        <div className={styles.buttonControlPanel}>
          <BackgroundTool address="imageUrl" storage="imageUrl" />
        </div>
      ) : null}
      {userRole === USER_ROLES.ADMINS ? (
        <Link to={'/admin-chat'} style={{ textDecoration: 'none' }}>
          <div className={styles.buttonControlPanel}>Admin Chat</div>
        </Link>
      ) : (
        <div
          className={styles.buttonControlPanel}
          style={{ display: 'none', cursor: 'default' }}
        >
          Bloque de relleno
        </div>
      )}
      {userRole === USER_ROLES.ADMINS ? (
        <div>
          <p
            className={styles.buttonControlPanel}
            style={{ textDecoration: 'none' }}
            onClick={() => setOpenTest(true)}
          >
            Test
          </p>
        </div>
      ) : (
        <div className={styles.buttonControlPanel}>Selector</div>
      )}
      {openTest ? <TestComponents setOpenTest={setOpenTest} /> : ''}
      {userRole === USER_ROLES.ADMINS ? (
        <Link to={'/admin-dashBoard'} style={{ textDecoration: 'none' }}>
          <div className={styles.buttonControlPanel}>Control Panel</div>
        </Link>
      ) : (
        <div
          className={styles.buttonControlPanel}
          style={{ display: 'none', cursor: 'default' }}
        >
          Bloque de relleno
        </div>
      )}
      {userRole === USER_ROLES.ADMINS ? (
        <div className={styles.buttonControlPanel}>
          <ChangeBackgroundToolPanel text="C. Whiteboard" />
        </div>
      ) : null}
    </>
  );
}
