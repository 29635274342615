import React, { useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { IoIosClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import ViewListFilesExam from 'components/Exam/ExamEditor/examEditoComponents/ViewListFilesExam';
import { USER_ROLES } from 'enums/constants.enum';
import { useUserRole } from 'services/cognito.service';

import './styles/TestComponentsStyle.css';

const TestComponents = ({ setOpenTest }) => {
  const history = useHistory();
  const userRole = useUserRole();
  const [viewFileExams, setviewFileExams] = useState(false);

  const handleLinkNewExam = () => {
    history.push('/admin/exam-editor/902d8a98-f2c8-48bf-875b-a3545c7845b1');
  };

  return (
    <>
      {!viewFileExams ? (
        <section className="testComponents__container">
          <span onClick={() => setOpenTest(false)} className="testComponents_onClose">
            <IoIosClose size={30} />
          </span>
          <article className={`testComponents__article`}>
            <div className="testComponets__icon" onClick={() => setviewFileExams(true)}>
              <CiSearch
                size={48}
                color="white"
                style={{ transform: 'translateY(-0.2em)' }}
              />
              <p style={{ transform: 'translateY(-0.3em)' }} className="test_fing">
                Find
              </p>
            </div>
            {userRole !== USER_ROLES.STUDENTS && (
              <div
                className={`testComponets__icon`}
                onClick={() => {
                  handleLinkNewExam();
                  localStorage.setItem('fileNameExam', 'new');
                  localStorage.setItem('fileExamID', 'new');
                }}
              >
                <svg
                  width="43"
                  height="43"
                  viewBox="0 15 87 87"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="12"
                    y="23"
                    width="63"
                    height="64"
                    rx="8"
                    stroke="white"
                    stroke-width="4"
                  />
                  <rect
                    x="31.8526"
                    y="71.2571"
                    width="58.9119"
                    height="6.49592"
                    transform="rotate(-53.0088 31.8526 71.2571)"
                    stroke="white"
                    stroke-width="2"
                  />
                  <path d="M31 71L31.8819 71.3263L28.5 80.5L28 81L31 71Z" fill="white" />
                  <path
                    d="M37.301 76.4803L36.3966 74.9642L28.5 80.5L28 81L37.301 76.4803Z"
                    fill="white"
                  />
                </svg>

                <p>New exam</p>
              </div>
            )}
          </article>
        </section>
      ) : (
        <ViewListFilesExam
          viewFileExams={viewFileExams}
          setviewFileExams={setviewFileExams}
        />
      )}
    </>
  );
};

export default TestComponents;
