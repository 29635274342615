import React, { useEffect, useState } from 'react';
import { useSocket } from 'providers/SocketProvider';

function SocketExamListener({
  getNextExamSection,
  getPreviousExamSection,
  classRoomId,
  setterSocket
}) {
  const { whiteBoardSocket, createWhiteBoardSocket } = useSocket();

  useEffect(() => {
    if (!classRoomId && whiteBoardSocket) {
      return;
    }

    async function initializeSocket() {
      if (!whiteBoardSocket && classRoomId) {
        // Example of classRoomId; this should be passed or fetched accordingly
        await createWhiteBoardSocket(classRoomId);
      }
    }

    initializeSocket();
  }, [classRoomId, createWhiteBoardSocket]);

  useEffect(() => {
    if (!classRoomId || !whiteBoardSocket) {
      return;
    }
    setterSocket(whiteBoardSocket);

    whiteBoardSocket.on('NEXT_PUBLIC_EXAM_STEP', getNextExamSection);
    whiteBoardSocket.on('BACK_PUBLIC_EXAM_STEP', getPreviousExamSection);

    return () => {
      whiteBoardSocket.remove('NEXT_PUBLIC_EXAM_STEP', getNextExamSection);
      whiteBoardSocket.remove('BACK_PUBLIC_EXAM_STEP', getPreviousExamSection);
    };
  }, [whiteBoardSocket]);

  return null;
}

export default SocketExamListener;
