import { useEffect } from 'react';
import { UPDATE_CLASSROOM_BACKGROUND } from '../graphQL/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { useUserClassRoom } from '../../UserManagment/hooks/useUserClassRoom';
import { GET_CLASSROOM } from 'components/UserManagment/graphQL/queries';
import { useQueryAllClassRooms } from 'components/AdminDashboard/hooks/useQueryAllClassRooms';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const useUpdateClassRoomBackground = () => {
  const params = useParams();
  const { assignedClassRoom } = useUserClassRoom();
  const [updateBackgroundMutation, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_CLASSROOM_BACKGROUND);
  const num = 1;

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_CLASSROOM, {
    variables: { id: num },
    skip: true
  });

  const { classRoomsIds } = useQueryAllClassRooms();

  useEffect(() => {
    if (mutationError) {
      console.error('Mutation error:', mutationError);
    }
    if (queryError) {
      console.error('Query error:', queryError);
    }
  }, [mutationError, queryError]);

  const setClassRoomBackground = (backgroundImageUrl, defaultClassRoomId) => {
    const classNameRoomId = defaultClassRoomId ?? params.classRoomId;
    return updateBackgroundMutation({
      variables: { classRoomId: classNameRoomId, backgroundImageUrl }
    });
  };

  const applyToAllChangeBackground = async backgroundImageUrl => {
    for await (const id of classRoomsIds) {
      await setClassRoomBackground(backgroundImageUrl, id);
    }
  };

  return {
    setClassRoomBackground,
    applyToAllChangeBackground,
    mutationLoading,
    queryLoading,
    mutationError,
    queryError,
    queryData
  };
};
