import { IMG_UPLOAD_KEYS } from 'enums/constants.enum';
import { SHAPE_TYPES } from 'enums/whiteBoard.enum';
import * as Actions from '../store/actions/WhiteBoardActions';

export const ifIsAudioComponentOpenEmit = (
  shapeValues,
  pageValue,
  initialPageValue,
  whiteBoardSocket
) => {
  if (pageValue && initialPageValue > 0) {
    window.sessionStorage.setItem(
      IMG_UPLOAD_KEYS.IS_NEW_WHITE_BOARD_MODIFICATION,
      'true'
    );
    const updatedShapes = {
      ...shapeValues[0],
      page: pageValue
    };

    if (whiteBoardSocket) {
      whiteBoardSocket.emit(Actions.CHANGE_PAGE_AUDIO, updatedShapes);
    }
  }
};

export const getAudioPageValue = shapes => {
  const audioShape = shapes.find(shape => shape.shapeType === SHAPE_TYPES.AUDIO);
  return audioShape ? audioShape.page : 0;
};
