import React from 'react';
import { useSocket } from 'providers/SocketProvider';
import { IoIosClose } from 'react-icons/io';
import './styles/ConfirmPublicExamModal.css';

function ConfirmPublicExamModal({ show, handleReject, selectedExamUrl, setIsModalOpen }) {
  const { whiteBoardSocket } = useSocket();

  const handleConfirm = () => {
    if (whiteBoardSocket) {
      whiteBoardSocket.emit('OPEN_PUBLIC_EXAM_IN_WHTEBOARD', {
        examUrl: selectedExamUrl
      });
    }
  };

  if (!show) return null;

  return (
    <div className="overlay_ConfirmPublicExamModal" style={{ zIndex: show && 9999999 }}>
      <div className="modalContent" style={{ zIndex: 9999999 }}>
        <IoIosClose
          className="click_close"
          size={25}
          onClick={() => setIsModalOpen(false)}
        />
        <div className="modalButtons">
          <button className="confirmButton" onClick={handleConfirm}>
            Share View
          </button>
          <button className="cancelButton" onClick={handleReject}>
            View Yourself
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPublicExamModal;
