import React, { useState, useEffect } from 'react';
import '../../Exam/ExamEditor/examEditoComponents/ExamModal.scss';
import { IoIosClose } from 'react-icons/io';

const PublicExamModal = ({ examUrl, onClose, whiteBoardSocket }) => {
  const [backgroundClicked, setBackgroundClicked] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');

  const handleBackgroundClick = () => {
    setBackgroundClicked(prevState => !prevState);
  };

  useEffect(() => {
    // Serializa el contexto y la información del socket, y los agrega a la URL
    try {
      const encodedSocketInfo = encodeURIComponent(
        JSON.stringify(whiteBoardSocket._authParams.classRoomId)
      );
      // whiteBoardSocket._authParams.classRoomId
      setIframeSrc(`/take-exam/${examUrl}?classRoomId=${encodedSocketInfo}`);
    } catch (error) {
      console.error('Failed to encode context or socket data:', error);
    }
  }, [whiteBoardSocket, examUrl]);

  return (
    <div className="exam-modal-overlay">
      <div
        className={`exam-modal-content ${backgroundClicked ? 'custom-background' : ''}`}
      >
        {' '}
        <div className="icon-container">
          <div
            style={{
              fontSize: '20px',
              color: 'grey',
              cursor: 'pointer',
              fontWeight: '600'
            }}
            className="click-changeViewListFilesExam"
            onClick={handleBackgroundClick}
          >
            T
          </div>
          <IoIosClose
            size={35}
            color="grey"
            className="click-closeViewListFilesExam"
            onClick={onClose}
          />
        </div>
        <iframe src={iframeSrc} title="Exam" />
      </div>
    </div>
  );
};

export default PublicExamModal;
