import React, { Fragment, useState } from 'react';
/** @jsxImportSource @emotion/react */
import './styles.css';

import { useUserClassRoom } from '../../UserManagment/hooks/useUserClassRoom';
import { USER_ROLES } from 'enums/constants.enum';
import { useUserRole } from 'services/cognito.service';

import LockTool from './LockTool';
import PageLeftTool from './PageLeftTool';
import PageRightTool from './PageRightTool';
import UploadFileTool from './UploadFileTool';
import ChangeBackgroundTool from './ChangeBackgroundTool';
import FilesTool from './FilesTool';
import IconsColorTool from './IconsColorTool';
import WhiteBoardThemeTool from './WhiteBoardThemeTool';
// import SplitScreenTool from './SplitScreenTool';
import BookingTool from './BookingTool';
import ChatOpenerTool from './ChatOpenerTool';
import VoiceCallTool from './VoiceCallTool';
import DigitalBookAccess from './DigitalBookAccess';
import NotesTool from './NotesTool';
import TestComponents from '../../UserManagment/StudentProfile/components/ButtonControlPanel/ButtonControlPanelComponents/TestComponents';
import ViewListFilesExam from 'components/Exam/ExamEditor/examEditoComponents/ViewListFilesExam';

const BottomBar = props => {
  const [openTest, setOpenTest] = useState(false);
  const [openBackground, setOpenBackground] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const { setIsVoiceOrVideo, isVoiceOrVideo } = props;
  const { isDisabledAndStudent, isCrystalTheme } = useUserClassRoom();
  const userRole = useUserRole();
  const [viewFileExams, setviewFileExams] = useState(false);

  const icons = () => {
    return (
      <Fragment>
        <PageLeftTool whiteBoardSocket={props.whiteBoardSocket} color={props.color} />
        <UploadFileTool
          color={props.color}
          openChat={props.openChat}
          openGallery={openGallery}
          setOpenGallery={setOpenGallery}
          openBackground={openBackground}
          setOpenBackground={setOpenBackground}
          whiteBoardSocket={props.whiteBoardSocket}
        />
        <FilesTool color={props.color} onClick={() => setOpenGallery(true)} />
        <VoiceCallTool
          color={props.color}
          setIsVoiceOrVideo={setIsVoiceOrVideo}
          isVoiceOrVideo={isVoiceOrVideo}
        />
        <LockTool color={props.color} />
        <IconsColorTool color={props.color} onClick={() => props.changeModeColor()} />
        <ChangeBackgroundTool
          color={props.color}
          onClick={() => setOpenBackground(true)}
        />
        <WhiteBoardThemeTool color={props.color} />
        <DigitalBookAccess color={props.color} />
        <NotesTool
          onClick={() => props.setOpenNotes(openNotes => !openNotes)}
          color={props.color}
          isCrystalTheme={isCrystalTheme}
          className={
            isCrystalTheme
              ? 'whiteboard-container-notes-crystal'
              : 'whiteboard-container-notes-normal'
          }
        />
        <BookingTool
          color={props.color}
          onClick={() => {
            setOpenTest(true);
            setviewFileExams(true);
          }}
        />
        {openTest && userRole !== USER_ROLES.STUDENTS ? (
          <TestComponents setOpenTest={setOpenTest} />
        ) : (
          ''
        )}
        {openTest && userRole === USER_ROLES.STUDENTS ? (
          <ViewListFilesExam
            viewFileExams={viewFileExams}
            setviewFileExams={setviewFileExams}
          />
        ) : (
          ''
        )}

        <ChatOpenerTool
          onClick={() => props.setopenChat(!props.openChat)}
          color={props.color}
        />
        <PageRightTool color={props.color} whiteBoardSocket={props.whiteBoardSocket} />
      </Fragment>
    );
  };

  if (isDisabledAndStudent || !props.isVisible) {
    return null;
  }

  return (
    <div
      className={isCrystalTheme ? 'modern-model__page-icon' : 'clasic-model__page-icon'}
    >
      {icons()}
    </div>
  );
};

export default BottomBar;
