import React, { useEffect, useState, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import 'moment-duration-format';
import './Bar.sass';

import { TOOLS } from 'enums/whiteBoard.enum';

export default function Bar(props) {
  const {
    duration,
    curTime,
    onTimeUpdate,
    isCrystal,
    toggleAudioBar,
    selectedTool,
    audioRef
  } = props;
  const [waveformBars, setWaveformBars] = useState([]);
  const waveformRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const viewportWidth = Math.round(document.documentElement.clientWidth / 100);

  useEffect(() => {
    const calculateBars = () => {
      const vw = Math.round(document.documentElement.clientWidth / 100);
      const baseBars = isCrystal ? 13 : 14;
      const baseVw = vw > 5 ? 0.8 : 1.6;
      const bars = Math.round((vw / baseVw) * baseBars);
      return bars;
    };

    const updateWaveformBars = () => {
      const bars = calculateBars();
      const newBars = Array(bars)
        .fill(0)
        .map(() => ({
          height: `${Math.random() * 100}%`
        }));
      setWaveformBars(newBars);
    };

    updateWaveformBars();
    window.addEventListener('resize', updateWaveformBars);
    return () => window.removeEventListener('resize', updateWaveformBars);
  }, [isCrystal]);

  useEffect(() => {
    if (duration > 0 && waveformBars.length > 0) {
      const barsToFill = Math.round((curTime / duration) * waveformBars.length);
      setWaveformBars(prevBars =>
        prevBars.map((bar, index) => ({
          ...bar,
          filled: index < barsToFill
        }))
      );
    }
  }, [curTime, duration, waveformBars.length]);

  useEffect(() => {
    const barElement = document.querySelector('.bar');
    if (barElement) {
      barElement.style.setProperty('--cur-time', `"${formatDuration(curTime)}"`);
    }
  }, [curTime]);

  function formatDuration(duration) {
    return moment.duration(duration, 'seconds').format('mm:ss', { trim: false });
  }

  function calcClickedTime(e) {
    if (!waveformRef.current) return 0;

    const waveform = waveformRef.current;
    const vw = Math.round(document.documentElement.clientWidth / 100);
    const isVertical = vw <= 5;

    let clickPositionInPage, waveformStart, waveformSize;

    clickPositionInPage = e.pageX || e.touches[0].pageX;
    waveformStart = waveform.getBoundingClientRect().left + window.scrollX;
    waveformSize = waveform.offsetWidth;

    const clickPositionInWaveform = clickPositionInPage - waveformStart;
    const barSize = waveformSize / waveformBars.length;
    const clickedBarIndex = Math.floor(clickPositionInWaveform / barSize);
    const timePerBar = duration / waveformBars.length;

    const clickedTime = clickedBarIndex * timePerBar;
    return isVertical ? duration - clickedTime : clickedTime;
  }

  const handleMouseDown = e => {
    setIsDragging(true);
    handleDrag(e);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    handleDrag(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = e => {
    setIsDragging(true);
    handleDrag(e);
  };

  const handleTouchMove = e => {
    if (!isDragging) return;
    handleDrag(e);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleDrag = e => {
    const newTime = calcClickedTime(e);
    const target = e.target;
    if (target.classList.contains('waveform__bar--filled')) {
      return;
    }
    audioRef.current.currentTime = newTime;
    onTimeUpdate(newTime);
  };

  const sliderClass = `waveform__slider ${
    selectedTool !== TOOLS.POINTER && viewportWidth > 11 ? 'no-pointer-events' : ''
  }`;

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleTouchEnd);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isDragging]);

  return (
    <>
      <div className="bar">
        <div className="bar__time__left">{formatDuration(curTime)}</div>
        <div
          className="waveform"
          onPointerDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          ref={waveformRef}
        >
          {waveformBars.map((bar, i) => (
            <div
              key={i}
              className={`waveform__bar ${bar.filled ? 'waveform__bar--filled' : ''}`}
              style={{ height: bar.height }}
            />
          ))}
          <div
            className={sliderClass}
            style={{ left: `${(curTime / duration) * 100}%` }}
          />
        </div>
        <div className="bar__time__right">{formatDuration(duration)}</div>
        <button
          className="audio-close top-right"
          onClick={toggleAudioBar}
          onTouchStart={toggleAudioBar}
        >
          <CloseIcon className="close-icon" />
        </button>
      </div>
    </>
  );
}
