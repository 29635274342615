import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LeftIconBlack } from 'assets/whiteboard/black/left-black.svg';
import { ReactComponent as LeftIconGrey } from 'assets/whiteboard/grey/left-grey.svg';
import { ReactComponent as LeftIconWhite } from 'assets/whiteboard/white/left-white.svg';
import { ifIsAudioComponentOpenEmit, getAudioPageValue } from 'utils/audioComponentUtils';
// enums
import { ICONS_COLORS } from '../../../enums/constants.enum';
import * as Actions from '../../../store/actions/WhiteBoardActions';

const PageLeft = ({ whiteBoardSocket, color, shape, requestUpdatePage, page }) => {
  const goToPage = () => {
    const shapeValues = Object.values(shape);
    const initialPageValue = getAudioPageValue(shapeValues);
    const pageValue = initialPageValue > 1 ? initialPageValue - 1 : 1;

    ifIsAudioComponentOpenEmit(
      shapeValues,
      pageValue,
      initialPageValue,
      whiteBoardSocket
    );

    requestUpdatePage({ page: Math.max(page - 1, 1) });
  };

  return (
    <div onClick={goToPage}>
      {color === ICONS_COLORS.BLACK && (
        <LeftIconBlack className="page-flecha-icon-item" />
      )}
      {color === ICONS_COLORS.WHITE && (
        <LeftIconWhite className="page-flecha-icon-item" />
      )}
      {color === ICONS_COLORS.GREY && <LeftIconGrey className="page-flecha-icon-item" />}
    </div>
  );
};

const mapStateToProps = state => ({
  page: state.whiteBoard.page,
  shape: state.whiteBoard.shapes
});

const mapDispatchToProps = dispatch => {
  const { requestUpdatePage } = Actions;

  return bindActionCreators({ requestUpdatePage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLeft);
