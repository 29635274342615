export const FOLDER_NAMES = {
  DEFAULT: 'default'
};

export const FOLDER_MODES = {
  EXAM_STUDENT: 'exam-student',
  EXAM_EDITION: 'exam-edition',
  FOLDER: 'folder',
  BACKGROUND_FILE: 'backgroundFile',
  BACKGROUND_FOLDER: 'backgroundFolder'
};
